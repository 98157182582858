<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.nomination_circular_list') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <iq-card>
              <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('portal.workshop_registration_nata')}}</h4>
              </template>
              <template v-slot:body>
                  <b-row>
                      <b-col sm="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col sm="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('elearning_config.organization')"
                            label-for="organization"
                        >
                          <v-select name="organization"
                              v-model="search.org_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= orgList
                              :placeholder="$t('globalTrans.select')"
                          />
                        </b-form-group>
                      </b-col> -->
                      <b-col sm="4">
                          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                      </b-col>
                      <!-- <b-col sm="4">
                          <b-button class="float-right blink_me btn-lg" style="color: red;font-size: 22px" variant="outline-success" type="button" @click="goToRegistration()"><b>{{ $i18n.locale === 'en' ? 'Online Registration' : 'অনলাইন ট্রেনিং রেজিস্ট্রেশন' }}</b></b-button>
                      </b-col> -->
                  </b-row>
              </template>
          </iq-card>
          <b-row>
              <b-col md="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                          <h4 class="card-title">{{$t('portal.workshop_registration')}}</h4>
                      </template>
                      <template v-slot:body>
                          <b-overlay :show="loadingData">
                              <b-row>
                                  <b-col md="12" class="table-responsive">
                                      <b-table  thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                          <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                          </template>
                                          <template v-slot:cell(type_name)="data">
                                          <span class="capitalize">{{ data.item.type_name }}</span>
                                          </template>
                                          <template v-slot:cell(application_deadline)="data">
                                            <span v-if="data.item.application_deadline" class="capitalize">{{ data.item.application_deadline | dateFormat }}</span>
                                          </template>
                                          <template v-slot:cell(training_start_date)="data">
                                            <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                                          </template>
                                          <template v-slot:cell(training_end_date)="data">
                                            <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                                          </template>
                                          <template v-slot:cell(status)="data">
                                              <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                              <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                          </template>
                                          <template v-slot:cell(action)="data">
                                              <b-button class="mr-1 mt-1 mb-1" title="View Details" v-b-modal.modal-5 variant="info" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i> {{ $t('globalTrans.view')}}</b-button>
                                              <a v-if="!(data.item.application_deadline) || data.item.application_deadline >= applicationDeadline"  @click="setResearchServiceUrl(data.item)" href="javascript:" title="Apply" class="btn btn-primary" size="sm">
                                               {{ $t('externalTraining.apply')}}
                                              </a>
                                          </template>
                                      </b-table>
                                      <b-pagination
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total-rows="pagination.totalRows"
                                          @input="searchData"
                                          />
                                  </b-col>
                              </b-row>
                          </b-overlay>
                      </template>
                  </iq-card>
              </b-col>
          </b-row>
          <b-modal id="modal-5" size="lg" :title="$t('portal.nomination_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <Details :item="item" :key="id" ref="details"/>
          </b-modal>
      </b-container>
    </div>
    <b-modal ref="mymodalss" id="modal-4560" size="sm" :title="$t('globalTrans.type')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-col lg="12">
          <b-overlay :show="loading">
            <div style="height: 100px">
              <b-form-group>
                <template v-slot:label>
                  <br/>
                </template>
                <b-form-radio-group
                  v-model="training_type"
                  :options="trainingType"
                  stacked
                  class="mb-3"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </b-overlay>
        </b-col>
      </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/EventBusLayout'
const today = new Date().toISOString().substr(0, 10)

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          training_type: 0,
          headerText: { text_en: '', text_bn: '' },
          service: {},
            search: {
              org_id: [],
              fiscal_year_id: 0
            },
            certiificateList: [],
            id: 0,
            item: '',
            applicationDeadline: today,
            loadingData: true,
            appliedList: [],
            tmpFiscalYearList: [],
            fiscalYearList: []
        }
    },
    watch: {
      training_type: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal !== 0) {
            this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
              trainingType: newVal,
              trainingOrg: 12
            })
            this.$router.push('/auth/login')
          }
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fiscalYearList = this.fiscalYearLocale(this.tmpFiscalYearList)
        }
      }
    },
    computed: {
      batchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
      trainingType: function () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
        ]
      },
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      accommodationList () {
          const accommodations = [
              { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
              { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
          ]
          return accommodations
      },
      // fiscalYearList: function () {
      //   return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
      // },
      orgList: function () {
        return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.Portal.traineeTranerObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.training_application')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('globalTrans.category'), class: 'text-left' },
              { label: this.$t('globalTrans.title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
              // { label: this.$t('elearning_iabm.application_deadline'), class: 'text-left' },
              { label: this.$t('globalTrans.start_date'), class: 'text-left' },
              { label: this.$t('globalTrans.end_date'), class: 'text-left' },
              // { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year_bn' },
              { key: 'training_category_bn' },
              { key: 'training_title_bn' },
              { key: 'batch_name_bn' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year' },
              { key: 'training_category' },
              { key: 'training_title_en' },
              { key: 'batch_name' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.getFiscalYearList()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        goToRegistration () {
          const url = '/trainee-trainer-panel/circular-list'
          this.$store.dispatch('Portal/setServiceUrl', {
            externalPanelServiceUrl: url,
            externalPanelCurrentServiceUrl: url
          })
          this.$refs.mymodalss.show()
        },
        setResearchServiceUrl (item) {
          const url = '/trainee-trainer-panel/circular-list'
          this.$store.dispatch('Portal/setServiceUrl', {
            externalPanelServiceUrl: url,
            externalPanelCurrentServiceUrl: url
          })
          this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
            circularId: item.id,
            circularMemoNo: item.circular_memo_no,
            trainingTitleId: item.training_title_id,
            trainingType: 2,
            trainingOrg: 12
          })
          this.$router.push('/auth/login')
        },
        fiscalYearLocale (data) {
          return data.map(item => {
            return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
          })
        },
        fiscalYearBnAdd (data) {
          const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
          const bnAdded = data.map(item => {
            const fiscalYearData = {}
            const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
            fiscalYearData.text_bn = result.join('')
            return Object.assign({}, item, fiscalYearData)
          })
          return bnAdded
        },
        getCurrentFiscalYearId (fiscalYearList) {
            if (fiscalYearList.length === 0) {
              return
            }
            const currentDate = new Date()
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            /** Define the year position  */
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            let currentFiscalYearId = 0
            fiscalYearList.forEach(element => {
              if (element.text_en.indexOf(yearStr) === yearPosition) {
                currentFiscalYearId = element.value
              }
            })
            return currentFiscalYearId
        },
        async getFiscalYearList () {
            const result = await RestApi.getData(commonServiceBaseUrl, 'fiscal-year-list', null)
            if (result.success) {
              const dataList = this.fiscalYearBnAdd(result.data)
              this.tmpFiscalYearList = dataList
              this.fiscalYearList = this.fiscalYearLocale(dataList)
              this.search.fiscal_year_id = this.getCurrentFiscalYearId(dataList)
              this.loadData()
            }
        },
        async loadData () {
          this.loadingData = true
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/portal-circular', params).then(response => {
            if (response.success) {
              setTimeout(async () => {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
                this.loadingData = false
              }, 8000)
            } else {
              this.loadingData = false
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.Portal.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const officeObj = this.$store.state.Portal.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }
            const officeTypeObj = this.$store.state.Portal.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.Portal.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            // const trainingTitleObj = this.$store.state.Portal.trainObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            // const trainingTitleData = {}
            // if (typeof trainingTitleObj !== 'undefined') {
            //   trainingTitleData.training_title = trainingTitleObj.text_en
            //   trainingTitleData.training_title_bn = trainingTitleObj.text_bn
            // } else {
            //   trainingTitleData.training_title = ''
            //   trainingTitleData.training_title_bn = ''
            // }
            const trainingTypeObj = this.$store.state.Portal.trainObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const trainingTypeData = {}
            if (typeof trainingTypeObj !== 'undefined') {
              trainingTypeData.training_type = trainingTypeObj.text_en
              trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
              trainingTypeData.training_type = ''
              trainingTypeData.training_type_bn = ''
            }
            const trainingCategoryObj = this.$store.state.Portal.trainObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const trainingCategoryData = {}
            if (typeof trainingCategoryObj !== 'undefined') {
              trainingCategoryData.training_category = trainingCategoryObj.text_en
              trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
            } else {
              trainingCategoryData.training_category = ''
              trainingCategoryData.training_category_bn = ''
            }

            const batchObj = this.batchList.find(doc => doc.value === parseInt(item.batch_no))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch_name = batchObj.text_en
              batchData.batch_name_bn = batchObj.text_bn
            } else {
              batchData.batch_name = ''
              batchData.batch_name_bn = ''
            }

            return Object.assign({}, item, orgData, officeData, officeTypeData, fiscalYearData, trainingTypeData, trainingCategoryData, batchData)
          })
          return listData
        }
        // pdfExport () {
        //   const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
        // }
    }
}
</script>
<style scoped>
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: #3E69F1;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
